// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'

import supportedThemes from './themes/__supportedThemes.js'

import RegisterForm from 'components/RegisterForm'
import transDomain from './translations/index.translations'
import MainLogo from '../MainLogo'
import withTheme from 'hoc/withTheme'

import type { Theme } from './themes/__supportedThemes'

import styles from './styles.module.scss'

type Props = {
    theme: Theme
}

function Hero (props: Props) {
    return (
        <div
            className={styles.hero}
            style={{ '--primary-color': props.theme.primaryColor }}
        >
            <div className={styles.bgWrapper} style={{
                '--hero-bg': `url(${props.theme.background})`,
                '--hero-bg-tablet': `url(${props.theme.backgroundTablet})`,
                '--hero-bg-mobile': `url(${props.theme.backgroundMobile})`,
            }}>
                <div className={styles.bgWrapperGrid}>
                    <div className={styles.logoContainer}>
                        <MainLogo/>
                    </div>

                    <div className={styles.formContainer}>
                        <RegisterForm/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Hero))
