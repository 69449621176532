// @flow
import * as React from 'react'
import cn from 'classnames'
import a from 'indefinite'
import { Store } from 'Store.js'
import { withTranslation, Trans } from 'react-i18next'
import { Currency } from 'i18n/NumberFormatter'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'

import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

function RegisterVoucherValue (props) {
    const { t, theme } = props
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const voucher = state.campaign.incentives.voucher
    const voucherArticle = a(voucher.name, { articleOnly: true })

    return (
        <div
            className={cn(styles.container, props.className)}
            style={{
                '--font-family': theme.fontFamily
            }}
        >
            <span className={cn(styles.title, {
                [styles.hiddenValue]: voucher.value === 0
            })} style={{ color: theme.titleColor }}>
                <Trans i18nKey='title' t={t} values={{
                    voucher: voucher.name,
                    type: voucher.type,
                    value: voucher.value,
                    article: voucherArticle
                }}>
                    Receive {voucherArticle} {voucher.name}<br />valued at {voucher.type}
                </Trans>
            </span>
            {voucher.value > 0 && (
                <span className={styles.value} style={{ color: theme.valueColor }}>
                    {currencyFormatter.format(voucher.value)}
                </span>
            )}
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(RegisterVoucherValue))
