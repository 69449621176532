import * as React from 'react'

import withTheme from 'hoc/withTheme'
import themes from './themes/__supportedThemes'

const EventLogo = React.forwardRef(function (props, ref) {
    return (
        <svg
            width="294px"
            height="281px"
            viewBox="0 0 294 281"
            {...props}
            ref={ref}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <path id="a-event-logo" d="M0 0L241.908126 0 241.908126 68.223166 0 68.223166z" />
            </defs>
            <g
                transform="translate(-278 -123) translate(278.503 123.678)"
                stroke="none"
                strokeWidth={1}
                fill="none"
                fillRule="evenodd"
            >
                <path
                    fill="#FFFFFF"
                    d="M32.8543249 234.32245L32.8543249 273.444154 260.050675 261.32245 260.050675 219.32245z"
                />
                <path
                    fill={props.secondaryColor}
                    d="M117.228034 0L117.228034 8.23609347 121.362624 8.23609347 121.362624 41.8015339 128.890338 41.8015339 128.890338 8.23609347 133.209119 8.23609347 133.209119 0z"
                />
                <path
                    fill={props.secondaryColor}
                    d="M147.516288 0L147.516288 15.9519473 143.201198 15.9519473 143.201198 0 135.67147 0 135.67147 41.8015339 143.201198 41.8015339 143.201198 23.8540204 147.516288 23.8540204 147.516288 41.8015339 155.045848 41.8015339 155.045848 0z"
                />
                <path
                    fill={props.secondaryColor}
                    d="M157.846287 0L157.846287 41.8015339 171.835146 41.8015339 171.835146 33.8979509 165.376014 33.8979509 165.376014 24.5745716 171.835146 24.5745716 171.835146 16.6726663 165.376014 16.6726663 165.376014 7.90341522 171.835146 7.90341522 171.835146 0z"
                />
                <g transform="translate(0 12.32)">
                    <path
                        d="M293.27 69.342v-36.3C293.27 14.795 278.479 0 260.23 0h-44.333c-16.454 0-29.793 13.34-29.793 29.795v159.239l.003.063c0 .507.01 1.002.024 1.49l.005.168c.055 1.694.19 3.289.402 4.785l.004.037.001-.001c.194 1.366.457 2.65.778 3.86l79.609-4.944 5.344-.331v21.347c3.896-1.443 7.478-3.256 9.556-4.753 6.996-6.06 11.44-14.987 11.44-24.968V80.395h-53.134v41.86l10.738.068v46.524a4.103 4.103 0 01-4.103 4.103h-11.285a4.103 4.103 0 01-4.103-4.103V47.248a4.103 4.103 0 014.103-4.103h11.285a4.103 4.103 0 014.103 4.103v22.094h42.397z"
                        fill={props.primaryColor}
                    />
                    <path
                        fill={props.primaryColor}
                        d="M171.835197 200.398533L171.835197 39.9021642 121.402331 39.9021642 121.402331 203.530876z"
                    />
                    <path
                        d="M45.85 111.069h9.074a5.398 5.398 0 015.398 5.398v53.79a5.398 5.398 0 01-5.398 5.4h-9.073v-64.588zm0-71.975h9.074a5.398 5.398 0 015.398 5.398v16.616a5.398 5.398 0 01-5.398 5.398h-9.073V39.094zM20.302 214.54v-4.73l4.72-.293 76.2-4.733a32.857 32.857 0 006.294-19.386v-68.353c0-9.2-2.92-18.153-11.963-24.388a33.038 33.038 0 00-8.157-4.975c11.826-5.031 20.12-16.755 20.12-30.418V36.739C107.515 16.45 91.067 0 70.778 0H0v218.44h20.301v-3.9z"
                        fill={props.primaryColor}
                    />
                    <path
                        fill={props.ternaryColor}
                        d="M99.705977 239.492573L104.226229 239.211902 101.966103 231.699044z"
                    />
                    <g transform="translate(25.334 199.516)">
                        <path
                            d="M226.972 29.14c0 8.12-5.05 12.59-11.3 12.978-6.251.389-10.842-3.483-10.842-11.603V15.014l7.804-.485V30.03c0 2.37.848 4.066 3.214 3.919 2.366-.147 3.32-1.955 3.32-4.325v-15.5l7.804-.486V29.14zm-37.646 14.615c-7.098.44-12.89-5.067-12.89-13.342s5.792-14.462 12.89-14.903c7.133-.443 12.819 5.032 12.819 13.307s-5.756 14.499-12.82 14.938zm-22.038-7.257v8.353l-7.84.487v-8.352l-8.97-18.597 8.97-.557 3.991 9.504 3.99-10 8.83-.548-8.97 19.71zm-32.596 10.378L127.417 35.4v11.928l-7.84.486V20.308l7.84-.487V31.4l7.17-12.023 8.863-.55-8.617 13.705 9.182 13.766-9.323.579zm-18.647 1.158l-7.84.487-8.299-13.316v13.831l-7.84.487V22.017l7.84-.487 8.299 13.548V21.015l7.84-.487v27.506zm-34.01 2.112l-1.235-4.08-8.335.517-1.236 4.234-8.228.511 9.147-28.074 9.04-.561 9.111 26.94-8.263.513zm-20.8 1.292l-7.84.487V41.202l-7.452.463v10.723l-7.84.487V25.368l7.84-.487v9.907l7.452-.463V24.42l7.84-.487v27.506zm-25.251-19.1l-6.64.412v20.669l-7.84.486V33.237l-6.568.408v-6.838L35.984 25.5v6.838zM0 15.024v53.2l241.908-15.025V0L0 15.024z"
                            fill={props.ternaryColor}
                        />
                    </g>
                    <path
                        d="M214.66 222.99c-3.214.2-4.91 2.714-4.91 6.443 0 3.653 1.696 5.995 4.91 5.795 3.142-.195 4.873-2.75 4.873-6.402 0-3.73-1.73-6.03-4.874-5.836"
                        fill={props.ternaryColor}
                    />
                </g>
            </g>
        </svg>
    )
})

EventLogo.defaultProps = {
    x: 0,
    y: 0,
    width: '215px',
    height: '122px'
}

export default withTheme(themes)(EventLogo)
