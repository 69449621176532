// @flow
import * as React from 'react'
import { Redirect } from 'react-router-dom'

import SelectedCarVoucher from 'components/SelectedCarVoucher'
import LeadGenForm from 'components/LeadGenForm/Step3'
import StepBreadcrumbs from 'components/StepBreadcrumbs'
import RegisterIncentives from 'components/RegisterIncentives'
import Header from 'components/Header'
import LayoutWrapper from 'components/LayoutWrapper'
import { Store } from 'Store'
import { updateLead } from 'Actions'

import styles from './styles.module.scss'

type Props = {
    location: Location
}

export default (props: Props) => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)
    const [toSelection, setToSelection] = React.useState(false)

    if (!state.lead) {
        return <Redirect to={`${props.location.pathname.replace('/step-3', '')}${props.location.search}`} push />
    }

    const handleOnSelection = React.useCallback(() => {
        setToSelection(true)
    }, [])

    const model = state.campaign.models.find(
        m =>
            m.make === state.lead.fields.newVehicleMake &&
            m.name === state.lead.fields.newVehicleModel &&
            m.year === state.lead.fields.newVehicleModelYear
    )

    const color = state.lead.fields.newVehicleModelColor

    if (toSelection) {
        return <Redirect to={`${props.location.pathname.replace('step-3', 'step-2')}${props.location.search}`} push />
    }

    if (submitted) {
        return <Redirect to={`${props.location.pathname.replace('step-3', 'success')}${props.location.search}`} push />
    }

    return (
        <>
            <Header />

            <div className={styles.stepsContainer}>
                <StepBreadcrumbs currentStepIndex={3}/>
            </div>

            <LayoutWrapper className={styles.layoutWrapper}>
                <div className={styles.layout}>
                    <div className={styles.sidebar}>
                        <SelectedCarVoucher
                            onClick={handleOnSelection}
                            colorProp={color}
                            make={model.make}
                            name={model.name}
                            year={model.year}
                            image={model.images.front_34.m}
                            voucherValue={
                            state.campaign.incentives.voucher.type === 'variable'
                                ? model.voucherValue
                                : state.campaign.incentives.voucher.value
                            }
                            startingPrice={model.startingPrice}
                        />
                    </div>

                    <div className={styles.step}>
                        <div className={styles.formContainer}>
                            <LeadGenForm
                                onSubmit={async (data, done) => {
                                    try {
                                        let lead = {
                                            fields: data,
                                            isComplete: true
                                        }
                                        await updateLead(dispatch, state.code, lead)
                                        setSubmitted(true)
                                    } catch (err) {
                                        alert('Oops something went wrong')
                                        console.error(err)
                                        done(err)
                                    }
                                }}
                                initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                            />
                        </div>
                    </div>
                </div>
            </LayoutWrapper>
            <RegisterIncentives/>
        </>
    )
}
