
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsaedbb9072a08b60873510b0e88e7f1c2en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', 'aedbb9072a08b60873510b0e88e7f1c2', flattenObject(nsaedbb9072a08b60873510b0e88e7f1c2en_US), true, true);
        
            import nsaedbb9072a08b60873510b0e88e7f1c2en from './en.yaml'
            i18n.addResourceBundle('en', 'aedbb9072a08b60873510b0e88e7f1c2', flattenObject(nsaedbb9072a08b60873510b0e88e7f1c2en), true, true);
        
        export default 'aedbb9072a08b60873510b0e88e7f1c2';
    