// @flow
import React from 'react'
import { withTranslation } from 'react-i18next'
import cn from 'classnames'

import withTheme from 'hoc/withTheme'

import transDomain from './translations/index.translations'

import supportedThemes, { Theme } from './themes/__supportedThemes.js'

import styles from './styles.module.scss'

type Props = {
    theme: Theme,
    noAnimated: boolean,
    small: boolean,
    t: (() => {})
}

function LoadingIndicator (props: Props) {
    return (
        <div className={cn(styles.loadingIndicator, {
            [styles.small]: props.small,
            [styles.noAnimated]: props.noAnimated,
        })}>
            <svg
                width={100}
                height={103}
                viewBox="0 0 170 163"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                {...props}
            >
                <defs>
                    <path id="prefix__a" d="M0 0h139.961v39.472H0z" />
                </defs>
                <g fill="none" fillRule="evenodd">
                    <path
                        fill="#212121"
                        d="M67.878.678v4.765h2.393v19.42h4.355V5.443h2.499V.678zM85.402.678v9.229h-2.496V.677h-4.357v24.186h4.357V14.479h2.496v10.384h4.357V.678zM91.379.678v24.185h8.094V20.29h-3.737v-5.394h3.737v-4.572h-3.737V5.25h3.737V.678zM169.732 47.925V26.924c0-10.559-8.559-19.118-19.116-19.118h-25.65c-9.52 0-17.238 7.718-17.238 17.239v92.13l.002.037a29.823 29.823 0 00.017.96c.031.98.11 1.902.232 2.768l.003.021c.113.79.265 1.533.45 2.233l46.06-2.86 3.092-.192V132.493c2.254-.835 4.326-1.884 5.528-2.75 4.048-3.506 6.62-8.671 6.62-14.446V54.32h-30.743v24.22l6.213.039v26.917a2.374 2.374 0 01-2.374 2.374H136.3a2.374 2.374 0 01-2.374-2.374V35.142a2.374 2.374 0 012.374-2.374h6.53a2.374 2.374 0 012.373 2.374v12.783h24.53zM99.473 123.75V30.893h-29.18v94.671zM26.582 72.067h5.25a3.123 3.123 0 013.122 3.124v31.121a3.123 3.123 0 01-3.123 3.124h-5.25V72.067zm0-41.643h5.25a3.123 3.123 0 013.122 3.124v9.613a3.123 3.123 0 01-3.123 3.123h-5.25v-15.86zM11.799 131.933v-2.737l2.732-.17 44.086-2.738a19.01 19.01 0 003.642-11.216V75.525c0-5.324-1.69-10.503-6.922-14.11a19.115 19.115 0 00-4.72-2.88c6.843-2.91 11.642-9.693 11.642-17.598V29.062c0-11.74-9.516-21.256-21.255-21.256H.054v126.383h11.745v-2.256z"
            />
                    <path fill={props.theme.primaryColor} d="M57.74 146.37l2.616-.163-1.308-4.347z" />
                    <g transform="translate(14.711 123.24)">
                        <mask id="prefix__b" fill="#fff">
                            <use xlinkHref="#prefix__a" />
                        </mask>
                        <path
                            d="M131.32 16.86c0 4.697-2.922 7.284-6.539 7.508-3.616.225-6.272-2.015-6.272-6.713V8.686l4.515-.28v8.969c0 1.37.49 2.352 1.86 2.267 1.368-.085 1.92-1.131 1.92-2.502V8.17l4.516-.28v8.968zm-21.782 8.455c-4.106.255-7.457-2.93-7.457-7.719 0-4.787 3.35-8.367 7.457-8.622 4.128-.257 7.417 2.91 7.417 7.699 0 4.787-3.33 8.388-7.417 8.642zm-12.75-4.198v4.833l-4.536.281V21.4l-5.19-10.76 5.19-.322 2.31 5.499 2.308-5.786 5.108-.317-5.19 11.404zm-18.86 6.004l-4.208-6.64v6.901l-4.536.282V11.75l4.536-.282v6.698l4.148-6.955 5.128-.319-4.985 7.93 5.312 7.964-5.394.335zm-10.788.67l-4.536.282-4.801-7.704v8.002l-4.536.282V12.738l4.536-.281 4.801 7.838v-8.137l4.536-.281V27.79zm-19.676 1.222l-.716-2.36-4.822.299-.715 2.45-4.76.295 5.292-16.243 5.23-.325 5.272 15.587-4.781.297zm-12.035.747l-4.536.282v-6.204l-4.312.268v6.204l-4.535.282V14.677l4.535-.281v5.731l4.312-.267v-5.732l4.536-.282V29.76zm-14.61-11.05l-3.841.238v11.958l-4.536.282V19.23l-3.8.236V15.51l12.177-.756v3.956zM0 8.692v30.78l139.961-8.693V0L0 8.692z"
                            fill={props.theme.primaryColor}
                            mask="url(#prefix__b)"
                />
                    </g>
                    <path
                        d="M124.25 136.822c-1.86.115-2.84 1.57-2.84 3.728 0 2.113.98 3.468 2.84 3.352 1.818-.113 2.819-1.591 2.819-3.704 0-2.158-1.001-3.49-2.82-3.376"
                        fill={props.theme.primaryColor}
            />
                </g>
            </svg>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(LoadingIndicator))
