// @flow
import * as React from 'react'
import cn from 'classnames'
import { Store } from 'Store.js'
import { withTranslation, Trans } from 'react-i18next'
import { Currency } from 'i18n/NumberFormatter'
import withTheme from 'hoc/withTheme'
import a from 'indefinite'
import supportedThemes from './themes/__supportedThemes.js'

import styles from './styles.module.scss'

import transDomain from './translations/index.translations'

function Sidebar (props) {
    const { t, theme, className } = props
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const voucher = state.campaign.incentives.voucher
    const voucherArticle = a(voucher.name, { articleOnly: true })

    return (
        <div className={cn(styles.container, className)} style={{
            backgroundColor: theme.backgroundColor,
            '--label-color': theme.valueColor,
            '--font-family': theme.fontFamily
        }}>
            <div
                style={{ backgroundImage: `url(${theme.background})` }}
                className={styles.sidebarImage}
            />

            <div className={styles.body}>
                <span
                    className={styles.message}
                    style={{ color: theme.secondaryColor }}>
                    {t('message')}
                </span>

                <div className={styles.sidebarVoucherValue} style={{
                    backgroundColor: theme.primaryColor
                }}>
                    <span className={styles.title} style={{ color: theme.titleColor }}>
                        <Trans i18nKey='title' t={t} values={{
                            province: state.campaign.client.address.province,
                            voucher: voucher.name,
                            type: voucher.type,
                            value: voucher.value,
                            article: voucherArticle
                        }}>
                            Upgrade your vehicle and receive {voucherArticle}<br />{voucher.name} valued at {voucher.type}
                        </Trans>
                    </span>

                    {voucher.value > 0 && (
                        <span className={styles.value}
                            style={{ color: theme.valueColor }}>
                            {currencyFormatter.format(voucher.value)}
                        </span>
                    )}
                </div>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Sidebar))
