
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns433791e54e80d638a95c8604b64f0265en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '433791e54e80d638a95c8604b64f0265', flattenObject(ns433791e54e80d638a95c8604b64f0265en_US), true, true);
        
            import ns433791e54e80d638a95c8604b64f0265en from './en.yaml'
            i18n.addResourceBundle('en', '433791e54e80d638a95c8604b64f0265', flattenObject(ns433791e54e80d638a95c8604b64f0265en), true, true);
        
        export default '433791e54e80d638a95c8604b64f0265';
    