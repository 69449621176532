// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import { Store } from 'Store'

import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm, { WithFormProps } from 'components/Form/Helpers/FormHOC'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

type Props = WithFormProps | {
    values: {
        firstName: ?string,
        lastName: ?string,
        email: ?string,
        mobilePhone: ?string,
    }
}

function Step1 (props: Props) {
    const { state } = React.useContext(Store)
    const { theme } = props
    const { t } = useTranslation(transDomain)
    const firstName = `${state.lead.fields.firstName || ''}`

    return (
        <div style={{
            '--font-family': theme.fontFamily
        }}>
            <div className={styles.heading}>
                <h2 style={{ color: theme.titleColor }}>
                    {t(props.theme.headingFieldKey, {
                        firstName
                    })}
                </h2>
                <div className={styles.description} style={{ color: theme.descriptionColor }}>
                    {t('description')}
                </div>
            </div>

            <Form.Row>
                <Form.RowColumns>
                    <Form.RowColumn size={0.5}>
                        <Form.Field
                            // error={props.errors.firstName || null}
                            defaultValue={props.values.firstName || null}
                            onChange={value => props.handleFieldChange('firstName', value)}
                            type='text'
                            label={t('label.firstName')}
                            placeholder={t('placeholder.firstName')}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn size={0.5}>
                        <Form.Field
                            // error={props.errors.lastName || null}
                            defaultValue={props.values.lastName || null}
                            onChange={value => props.handleFieldChange('lastName', value)}
                            type='text'
                            label={t('label.lastName')}
                            placeholder={t('placeholder.lastName')}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>
            <Form.Row>
                <Form.RowColumns>
                    <Form.RowColumn size={0.5}>
                        <Form.Field
                            // error={props.errors.email || null}
                            defaultValue={props.values.email || null}
                            onChange={value =>
                                props.handleFieldChange('email', value)
                            }
                            type='email'
                            label={t('label.emailAddress')}
                            placeholder={t('placeholder.emailAddress')}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn size={0.5}>
                        <Form.Field
                            // error={props.errors.eveningPhone || null}
                            defaultValue={props.values.mobilePhone || null}
                            onChange={value => props.handleFieldChange('mobilePhone', value)}
                            type='phone'
                            label={t('label.mobilePhone')}
                            placeholder={t('placeholder.mobilePhone')}
                            required={true}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <div className={styles.submitContainer}>
                <Form.Submit
                    isLoading={props.isLoading}
                    label={t('label.continueToStep2')}
                    style={{
                        background: theme.background,
                        color: theme.color
                    }}
                />
            </div>
        </div>
    )
}

export default withForm()(withTheme(supportedThemes)(Step1))
