// @flow
import * as React from 'react'

import withTheme from 'hoc/withTheme'
import { withTranslation } from 'react-i18next'

import supportedThemes, { Theme } from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'

import styles from './styles.module.scss'
import MainLogo from 'components/MainLogo/index.js'

import { Store } from 'Store.js'

type Props = {
    theme: Theme,
}

function Header (props: Props) {
    const { state } = React.useContext(Store)
    const firstName = state.lead.fields.firstName || null
    return (
        <div
            className={styles.header}
            style={{
                '--background-desktop': `url(${props.theme.backgroundDesktop})`,
                '--background-tablet': `url(${props.theme.backgroundTablet})`,
                '--background-mobile': `url(${props.theme.backgroundMobile})`,
            }}
        >
            <span className={styles.logoContainer}>
                <MainLogo />

                {firstName && (
                    <span className={styles.name}>
                        Welcome, {firstName}
                    </span>
                )}
            </span>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Header))
