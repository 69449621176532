// @flow
import * as React from 'react'
import RegistrationForm from 'components/RegistrationForm'
import { Currency } from 'i18n/NumberFormatter'

import { Store } from 'Store.js'
import { withTranslation, Trans } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import a from 'indefinite'

import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

function RegisterForm (props) {
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const voucher = state.campaign.incentives.voucher
    const voucherArticle = a(voucher.name, { articleOnly: true })

    return (
        <div className={styles.registerForm} style={{
            '--primary-font': props.theme.primaryFont,
        }}>
            <div className={styles.formContainer}>
                <div>
                    <h3 className={styles.title} style={{ color: props.theme.primaryColor }}>
                        <Trans t={props.t} i18nKey="title">
                            There is no better <br /> way to thank you!
                        </Trans>
                    </h3>

                    <span className={styles.subtitle} style={{
                        color: props.theme.secondaryColor,
                    }}>
                        <Trans t={props.t} i18nKey="subtitle" values={{
                            voucher: voucher.name,
                            type: voucher.type,
                            value: voucher.value > 0 ? currencyFormatter.format(voucher.value) : 0,
                            article: voucherArticle
                        }}>
                            Trade up into a new vehicle and receive {voucherArticle} <br /> {voucher.name} <strong>{currencyFormatter.format(voucher.value)}!</strong>
                        </Trans>
                    </span>
                </div>

                <RegistrationForm/>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(RegisterForm))
