
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nse55c2f63720bf0c8406deccd6a9e7a7den_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', 'e55c2f63720bf0c8406deccd6a9e7a7d', flattenObject(nse55c2f63720bf0c8406deccd6a9e7a7den_US), true, true);
        
            import nse55c2f63720bf0c8406deccd6a9e7a7den from './en.yaml'
            i18n.addResourceBundle('en', 'e55c2f63720bf0c8406deccd6a9e7a7d', flattenObject(nse55c2f63720bf0c8406deccd6a9e7a7den), true, true);
        
        export default 'e55c2f63720bf0c8406deccd6a9e7a7d';
    