// @flow

import React from 'react'
import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'

type Props = {
    children: React.children,
    backgroundColor: string,
}

function LayoutWrapper (props: Props) {
    return (
        <div style={{
            backgroundColor: props.theme.backgroundColor
        }} className={props.className}>
            {props.children}
        </div>
    )
}

export default withTheme(supportedThemes)(LayoutWrapper)
