// @flow
import * as React from 'react'
import { Redirect } from 'react-router-dom'

import Header from 'components/Header'
import LeadGenForm from 'components/LeadGenForm/Step2'
import RegisterIncentives from 'components/RegisterIncentives'
import StepBreadcrumbs from 'components/StepBreadcrumbs'
import LayoutWrapper from 'components/LayoutWrapper'

import { Store } from 'Store'
import { updateLead } from 'Actions'

import styles from './styles.module.scss'

type Props = {
    location: Location
}

export default (props: Props) => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)

    const handleSelect = React.useCallback(async (make, model, year, color) => {
        const lead = {
            ...state.lead,
            fields: {
                ...state.lead.fields,
                newVehicleMake: make,
                newVehicleModel: model,
                newVehicleModelYear: year,
                newVehicleModelColor: color
            }
        }
        await updateLead(dispatch, state.code, lead)
        setSubmitted(true)
    }, [state.lead, state.code, dispatch])

    if (!state.lead) {
        return <Redirect to={`${props.location.pathname.replace('/step-2', '')}${props.location.search}`} push />
    }

    if (submitted) {
        return <Redirect to={`${props.location.pathname.replace('step-2', 'step-3')}${props.location.search}`} push />
    }
    return (
        <>
            <Header/>

            <div className={styles.stepsContainer}>
                <StepBreadcrumbs currentStepIndex={2}/>
            </div>

            <LayoutWrapper className={styles.layoutWrapper}>
                <div className={styles.layout}>
                    <div className={styles.step}>
                        <div className={styles.formContainer}>
                            <LeadGenForm
                                onSelect={handleSelect}
                                onSubmit={async (data, done) => {
                                    try {
                                        let lead = {
                                            fields: data,
                                            isComplete: true
                                        }
                                        await updateLead(dispatch, state.code, lead)
                                        setSubmitted(true)
                                    } catch (err) {
                                        alert('Oops something went wrong')
                                        console.error(err)
                                        done(err)
                                    }
                                }}
                                initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                            />
                        </div>
                    </div>
                </div>
            </LayoutWrapper>
            <RegisterIncentives/>
        </>
    )
}
