
// @flow

import React from 'react'
import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import cn from 'classnames'

// import { Store } from 'Store.js'
import transDomain from './translations/index.translations'
import supportedThemes, { Theme } from './themes/__supportedThemes.js'
import styles from './styles.module.scss'

type Props = {
    theme: Theme,
    steps?: Array<[{ label: String, }]>,
    currentStepIndex: number
}

function StepBreadcrumbs (props: Props) {
    // const { state } = React.useContext(Store)
    return (
        <div className={styles.stepBreadcrumbsContainer}>
            <div className={styles.stepBreadcrumbs}>
                {props.steps.map((step, index) => (
                    <div style={{
                        flexBasis: `${100 / (props.steps).length}%`,
                        '--active-text-color': props.theme.activeTextColor,
                        '--inactive-text-color': props.theme.inactiveTextColor,
                        '--font-family': props.theme.fontFamily
                    }} className={cn(styles.label, {
                        [styles.__active]: props.currentStepIndex === index + 1
                    })} key={index}>
                        <span>{step.label}</span>
                    </div>
                ))}
            </div>

            <div className={styles.stepProgressContainer} style={{
                backgroundColor: props.theme.inactiveLineColor
            }}>
                <div
                    className={styles.stepProgressBar}
                    style={{
                        backgroundColor: props.theme.activeLineColor,
                        width: `${100 / props.steps.length}%`,
                        left: `${(100 / props.steps.length) * (props.currentStepIndex - 1)}%`
                    }}
                />
            </div>
        </div>
    )
}

StepBreadcrumbs.defaultProps = {
    steps: [
        {
            label: 'Contact Information'
        },
        {
            label: 'New Vehicle Selection'
        },
        {
            label: 'New Vehicle Details'
        }
    ]
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(StepBreadcrumbs))
